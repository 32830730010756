import { useState, useEffect } from "react"
// @mui
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
// routes
// components
import Iconify from "../../../components/iconify"
import {
  Check,
  Close,
  EditNoteOutlined,
  EditOutlined,
  PersonOutline,
  PictureAsPdf,
  SupportAgentOutlined,
  Send,
  UploadFileOutlined,
} from "@mui/icons-material"
import {
  BankReferenceChart,
  CreditApplication,
  PDFFile,
  UploadedFile,
} from "src/types"
import { useDocumentPDF } from "src/queries/vendors/useDocumentPDF"
import { CircularProgress } from "@mui/material"
import { confirm } from "src/components/confirm"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { useCreditApplication } from "src/queries/credit/useCreditApplication"
import { useSnackbar } from "notistack"
import { getStatusString } from "../utils"
import Label from "src/components/label"
import { fDateTime } from "src/utils/formatTime"
import { formatCurrency } from "src/utils/formatNumber"
import AssignmentSelectionDialog from "src/components/assignment/AssignmentSelectionDialog"
import { useUsers } from "src/queries/base/useUsers"
import { useAssignApplication } from "src/queries/credit/useAssignApplication"
import { stringAvatar } from "src/utils/avatar"
import ApprovalDialog from "./components/ApprovalDialog"
import MenuItem from "@mui/material/MenuItem"
import DropdownMenuButton from "src/components/dropdown-menu-button"
import RequestInformationDialog from "./components/RequestInformationDialog"
import { error } from "src/utils/logger"
import { Group } from "src/types"
import { Icon } from "@iconify/react"
import { startCase } from "lodash"
import { format } from "date-fns"
import RequestApprovalDialog from "./components/RequestApprovalDialog"
import SendCompletionReminderDialog from "./components/SendCompletionReminderDialog"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { convertNetTerms } from "src/statics"
import RequestCustomDocumentDialog from "./components/RequestCustomDocumentDialog"
import { useRequestedCustomDocument } from "src/queries/credit/useRequestedCustomDocument"
import useGenerateApplicationPdf from "src/hooks/useGenerateApplicationPdf"
import { createZipFileOfPDFs } from "src/utils/utils"
import useGenerateTradeReferencePdf from "src/hooks/useGenerateTradeReferencePdf"
import useGenerateDigitalBankReferencePdf from "src/hooks/useGenerateDigitalBankReferencePdf"
import { useGetBankReferenceChart } from "src/queries/credit/useGetBankReferenceChart"
import { useUploadedCreditReport } from "src/queries/credit/useUploadedCreditReport"
import { useExperianPurchasedReport } from "src/queries/vendors/useExperianSearch"
import { useCreditsafePurchasedReport } from "src/queries/vendors/useCreditSafeSearch"
import InvisibleBankReferenceChart from "./components/InvisibleBankReferenceChart"
import { fileData } from "src/utils/files"
import ApexCharts from "apexcharts"
import * as Sentry from "@sentry/react"
import { INVISIBLE_CHART_ID } from "src/statics"
import { useNavigate } from "react-router-dom"
import { useCreditRiskMonitorPurchasedReport } from "src/queries/vendors/useCreditRiskMonitorSearch"

export default function CreditApplicationDetailsToolbar(
  {
    application,
    refreshCommentTrigger,
    setRefreshCommentTrigger,
    refetchTimeline,
  }: {
    application: CreditApplication
    backLink: string
    refreshCommentTrigger: { current: number }
    setRefreshCommentTrigger: (value: number) => void
    refetchTimeline: () => void
  }, // onChangeStatus,
) {
  const { refetch: fetchPdf, isLoading: isFetchingPdf } = useDocumentPDF(
    application ? application.data.zohoReqId : "",
  )

  const { refetch: fetchPersonalGuarantyPdf } = useDocumentPDF(
    application.personalGuaranty
      ? application.personalGuaranty[0]?.documentRequestId
      : "",
    "guaranty",
  )

  const { data: bankReferenceChartData } = useGetBankReferenceChart(
    application.id,
  )

  const { data: purchasedExperianReports } = useExperianPurchasedReport(
    application.id || "",
  )

  const { data: purchasedCreditsafeReports } = useCreditsafePurchasedReport(
    application.id || "",
  )

  const { data: creditRiskMonitorReports } =
    useCreditRiskMonitorPurchasedReport(application.id as string)

  const { data: creditReport } = useUploadedCreditReport(application.id || "")

  const [showReminderDialog, setShowReminderDialog] = useState(false)

  const { data: requestedCustomDocuments, refetch: refetchRequests } =
    useRequestedCustomDocument(undefined, application.id)

  const { execute: generatePdf } = useGenerateApplicationPdf(application)
  const [singleUnsignedPDFLoading, setSingleUnsignedPDFLoading] =
    useState(false)
  const [allUnsignedPDFLoading, setAllUnsignedPDFLoading] = useState(false)
  const [allSignedPDFLoading, setAllSignedPDFLoading] = useState(false)

  const { execute: generateTradeReferencePdf } =
    useGenerateTradeReferencePdf(application)

  const { execute: assignApplication } = useAssignApplication()

  const { data: template } = useApplicationTemplate(
    (application && application.seller && application.seller.id) || undefined,
  )

  const { enqueueSnackbar } = useSnackbar()

  const { data: users } = useUsers()

  const { refetch } = useCreditApplication(application.id)

  const { execute: patchApplication } = usePatchApplication(() => {
    refetch()
    enqueueSnackbar("Application updated", { variant: "success" })
  })

  const [showRequestApprovalDialog, setShowRequestApprovalDialog] =
    useState(false)
  const [showApprovalDialog, setShowApprovalDialog] = useState(false)
  const [showRequestInfoDialog, setShowRequestInfoDialog] = useState(false)
  const [showCustomDocuments, setShowCustomDocuments] = useState(false)

  const [showAssignmentDialog, setShowAssignmentDialog] = useState<
    string | undefined
  >(undefined)

  const [bankReferenceChartImageUrl, setBankReferenceChartImageUrl] = useState<
    string | undefined
  >("")

  const [creditReportFiles, setcreditReportFiles] = useState<PDFFile[]>([])
  const [pdfFiles, setPdfFiles] = useState<PDFFile[]>([])
  const navigate = useNavigate()

  const { execute: generateBankReferencePdf } =
    useGenerateDigitalBankReferencePdf(
      application,
      bankReferenceChartData as BankReferenceChart,
      bankReferenceChartImageUrl,
    )
  const downloadSingleSignedPDF = () => {
    fetchPdf()
      .then((res) => {
        function saveByteArray(fileName: string, b64: string) {
          const link = document.createElement("a")
          link.href = "data:application/pdf;base64," + b64
          link.download = fileName
          link.click()
        }
        if (!res.data) throw new Error("No data")
        return saveByteArray(
          `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Credit Application ${format(
            new Date(application.createdAt as string),
            "yyyy/MM/dd",
          )}.pdf`,
          res.data,
        )
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const downloadSingleUnsignedPDF = () => {
    setSingleUnsignedPDFLoading(true)
    generatePdf()
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
      })

      .then((res) => {
        const link = document.createElement("a")
        link.href = res as string
        link.download = `${startCase(
          application.data.legalBusinessName ||
            `${application.data.firstName} ${application.data.lastName}`,
        )} Credit Application ${format(
          new Date(application.createdAt as string),
          "yyyy/MM/dd",
        )}.pdf`
        setSingleUnsignedPDFLoading(false)
        return link.click()
      })
      .catch((err) => {
        setSingleUnsignedPDFLoading(false)
        Sentry.captureException(err)
      })
  }

  const getExtensionFromPath = (path: string) => {
    const extension = ".pdf"
    const extension_list = [
      ".jpeg",
      ".pptx",
      ".potx",
      ".ppsx",
      ".ppam",
      ".pptm",
      ".potm",
      ".ppsm",
      ".docx",
      ".dotx",
      ".dotm",
      ".docm",
      ".doc",
      ".dot",
      ".xls",
      ".xlt",
      ".xla",
      ".ppt",
      ".pot",
      ".pps",
      ".ppa",
      ".mdb",
      ".png",
      ".jpg",
      ".msg",
      ".bmp",
      ".csv",
      ".txt",
      ".rtf",
    ]
    if (path && !path.toLowerCase().includes(".pdf")) {
      for (const ext of extension_list) {
        if (path.toLowerCase().includes(ext)) {
          return ext
        }
      }
    }
    return extension
  }

  const generateBankReferenceDataURI = () => {
    const bankChart = ApexCharts.getChartByID(INVISIBLE_CHART_ID)
    if (bankChart) {
      bankChart
        .dataURI()
        .then((res) => {
          const { imgURI } = res as { imgURI: string }
          setBankReferenceChartImageUrl(imgURI)
          return
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
    }
  }

  const generateBankReferenceChart = () => {
    return generateBankReferencePdf()
      .then((blob) => {
        pdfFiles.push({
          name: `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Bank Reference ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )}`,
          file: blob,
        })
        return generateTradeReferencePDF()
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generatePersonalGuaranty = () => {
    return fetchPersonalGuarantyPdf()
      .then((res) => {
        if (res.data) {
          pdfFiles.push({
            name: `${startCase(
              application.data.legalBusinessName ||
                `${application.data.firstName} ${application.data.lastName}`,
            )} Personal Guarantor ${format(
              new Date(application.createdAt as string),
              "yyyy-MM-dd",
            )}`,
            file: res.data,
          })
        }
        return finalizeDownload()
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generateTradeReferencePDF = () => {
    return generateTradeReferencePdf()
      .then((blob) => {
        pdfFiles.push({
          name: `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Trade Reference ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )}`,
          file: blob,
        })
        if (
          application.personalGuaranty &&
          application.personalGuaranty[0]?.documentRequestId
        ) {
          return generatePersonalGuaranty()
        } else {
          return finalizeDownload()
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generateSignedApplicationPDF = () => {
    fetchPdf()
      .then((res) => {
        if (res.data) {
          pdfFiles.push({
            name: `${startCase(
              application.data.legalBusinessName ||
                `${application.data.firstName} ${application.data.lastName}`,
            )} Credit Application ${format(
              new Date(application.createdAt as string),
              "yyyy-MM-dd",
            )}`,
            file: res.data,
          })
        }

        if (bankReferenceChartData && bankReferenceChartData.lastUpdated) {
          return generateBankReferenceDataURI()
        } else {
          return generateTradeReferencePDF()
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const generateUnsignedApplicationPDF = () => {
    generatePdf()
      .then((blob) => {
        pdfFiles.push({
          name: `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Credit Application ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )}`,
          file: blob,
        })
        if (bankReferenceChartData && bankReferenceChartData.lastUpdated) {
          return generateBankReferenceDataURI()
        } else {
          return generateTradeReferencePDF()
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  const finalizeDownload = () => {
    createZipFileOfPDFs(
      `${startCase(
        application.data.legalBusinessName ||
          `${application.data.firstName} ${application.data.lastName}`,
      )} Application Files`,
      pdfFiles,
    )
    setPdfFiles([])
    setAllUnsignedPDFLoading(false)
    setAllSignedPDFLoading(false)
    setBankReferenceChartImageUrl(undefined)
  }

  const downloadAllSignedPDFs = () => {
    setAllSignedPDFLoading(true)
    for (const pdfFile of creditReportFiles) {
      pdfFiles.push(pdfFile)
    }
    generateSignedApplicationPDF()
  }

  const downloadAllUnsignedPDFs = () => {
    setAllUnsignedPDFLoading(true)
    for (const pdfFile of creditReportFiles) {
      pdfFiles.push(pdfFile)
    }
    generateUnsignedApplicationPDF()
  }

  useEffect(() => {
    if (bankReferenceChartImageUrl) {
      generateBankReferenceChart()
    }
  }, [bankReferenceChartImageUrl])

  useEffect(() => {
    const currentReportFiles: PDFFile[] = []
    if (purchasedExperianReports && purchasedExperianReports.length > 0) {
      purchasedExperianReports.forEach((report, index) => {
        if (report.url) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Experian Report ${format(
            new Date(report.createdAt),
            "yyyy-MM-dd",
          )} ${index + 1}`
          fetch(report.url)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }
    if (purchasedCreditsafeReports && purchasedCreditsafeReports.length > 0) {
      purchasedCreditsafeReports.forEach((report, index) => {
        if (report.url) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} CreditSafe Report ${format(
            new Date(report.createdAt),
            "yyyy-MM-dd",
          )} ${index + 1}`
          fetch(report.url)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (creditRiskMonitorReports && creditRiskMonitorReports.length > 0) {
      creditRiskMonitorReports.forEach((report, index) => {
        if (report.url) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} CreditRiskMonitor Report ${format(
            new Date(report.createdAt),
            "yyyy-MM-dd",
          )} ${index + 1}`
          fetch(report.url)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (creditReport && creditReport.length > 0) {
      creditReport.forEach((report, index) => {
        if (report.file) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} Credit Report ${format(
            new Date(application.createdAt as string),
            "yyyy-MM-dd",
          )} ${index + 1}`
          const { name = "" } = fileData(report.file, fileName)
          fetch(report.file)
            .then((r) => {
              currentReportFiles.push({
                name,
                file: r.blob(),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (requestedCustomDocuments && requestedCustomDocuments.length > 0) {
      requestedCustomDocuments.forEach((document) => {
        if (document.file) {
          const fileName = `${startCase(
            application.data.legalBusinessName ||
              `${application.data.firstName} ${application.data.lastName}`,
          )} ${document.document.name}`
          fetch(document.file)
            .then((r) => {
              currentReportFiles.push({
                name: fileName,
                file: r.blob(),
                extension: getExtensionFromPath(document.file),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }

    if (application.files && application.files.length > 0) {
      application.files.forEach((file) => {
        const value = file as UploadedFile
        if (value.name && value.file) {
          fetch(value.file)
            .then((r) => {
              currentReportFiles.push({
                name: value.name,
                file: r.blob(),
                extension: getExtensionFromPath(value.file),
              })

              return
            })
            .catch((e) => console.error(e))
        }
      })
    }
    setcreditReportFiles(currentReportFiles)
  }, [
    purchasedExperianReports,
    purchasedCreditsafeReports,
    creditReport,
    creditRiskMonitorReports,
    application.data.legalBusinessName,
    application.data.firstName,
    application.data.lastName,
    application.createdAt,
    requestedCustomDocuments,
    application.files,
  ])
  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          <IconButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Stack spacing={0.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h4">
                {application.data.legalBusinessName ||
                  `${application.data.firstName} ${application.data.lastName}`}
              </Typography>
              <Label
                variant="soft"
                color={
                  (application.stage === 800 && "success") ||
                  (application.stage < 800 && "warning") ||
                  (application.stage === 900 && "error") ||
                  "default"
                }
              >
                {getStatusString(application.stage)}
              </Label>
              {application.data.customerId && (
                <Label variant="soft" color="default">
                  Customer ID {application.data.customerId}
                </Label>
              )}
            </Stack>
            {application.approvedAt && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Latest Approval
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.approvedAt as string)}
                </Label>
                {application.data.creditLimit && (
                  <Label variant="soft" color="default">
                    {formatCurrency(
                      application.data.creditLimit,
                      application.data.currency,
                    )}
                  </Label>
                )}
                {application.data.netTerms && (
                  <Label variant="soft" color="default">
                    {convertNetTerms(
                      application.data.netTerms,
                      template?.approvalTerms,
                    )}
                  </Label>
                )}
              </Stack>
            )}
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body2" sx={{ color: "text.disabled" }}>
                Submitted
              </Typography>
              <Label variant="soft" color="default">
                {fDateTime(application.createdAt)}
              </Label>
            </Stack>
            {application.lastViewed && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Last Accessed
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.lastViewed)}
                </Label>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box
          flexGrow={1}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "right",
          }}
        >
          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {application.stage == 800 && (
              <Button
                color="primary"
                variant="outlined"
                // endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                onClick={() => {
                  setShowApprovalDialog(true)
                }}
                sx={{ textTransform: "capitalize" }}
              >
                Edit Approval
              </Button>
            )}
            {application.stage < 800 && (
              <>
                <DropdownMenuButton title="Approve/Reject">
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      setShowApprovalDialog(true)
                    }}
                  >
                    <Check />
                    Approve
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      confirm("You are about to reject this application.", {
                        okColor: "error",
                        okLabel: "Reject",
                      })
                        .then(() => {
                          return patchApplication(
                            application.id as string,
                            {
                              stage: 900,
                            },
                            () => undefined,
                            () => undefined,
                            { notifyBuyer: "1" },
                          )
                        })
                        .catch((err) => {
                          error("error in confirm dialog", err)
                        })
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <Close style={{ fill: "red" }} />
                    Reject
                  </MenuItem>
                  <MenuItem
                    disableRipple
                    onClick={() => {
                      setShowRequestApprovalDialog(true)
                    }}
                  >
                    <Send />
                    Request Approval
                  </MenuItem>
                </DropdownMenuButton>
                <DropdownMenuButton title="Request from Applicant">
                  <MenuItem
                    disableRipple
                    onClick={() => setShowRequestInfoDialog(true)}
                  >
                    <EditNoteOutlined />
                    Additional Information
                  </MenuItem>

                  <MenuItem
                    disableRipple
                    onClick={() => setShowCustomDocuments(true)}
                  >
                    <UploadFileOutlined />
                    Custom Document
                  </MenuItem>

                  <MenuItem
                    disableRipple
                    onClick={() => {
                      setShowReminderDialog(true)
                    }}
                  >
                    <Icon
                      icon="mdi:email-outline"
                      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium"
                    />
                    Send Reminder
                  </MenuItem>
                </DropdownMenuButton>
              </>
            )}
          </Stack>
          {showReminderDialog && (
            <SendCompletionReminderDialog
              application={application}
              onClose={() => {
                setShowReminderDialog(false)
              }}
            />
          )}
          <Stack
            flexGrow={1}
            spacing={1.5}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            {!application.assignee ? (
              <Button
                variant="outlined"
                startIcon={<PersonOutline />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                Assign Credit Manager
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("CREDIT_MANAGER")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    {...stringAvatar(
                      `${
                        application.assignee.firstName
                          ? application.assignee.firstName
                          : application.assignee.email
                      }`,
                      {
                        width: 24,
                        height: 24,
                      },
                    )}
                  />
                  {application.assignee.firstName
                    ? `${application.assignee.firstName}${
                        application.assignee.lastName
                          ? " " + application.assignee.lastName[0]
                          : ""
                      }`
                    : application.assignee.email}
                </Box>
              </Button>
            )}
            {!application.salesRep ? (
              <Button
                variant="outlined"
                startIcon={<SupportAgentOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                Assign Sales Rep
              </Button>
            ) : (
              <Button
                variant="outlined"
                endIcon={<EditOutlined />}
                onClick={() => setShowAssignmentDialog("SALES")}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <SupportAgentOutlined />
                  {application.salesRep.firstName
                    ? `${application.salesRep.firstName}${
                        application.salesRep.lastName
                          ? " " + application.salesRep.lastName[0]
                          : ""
                      }`
                    : application.salesRep.email}
                </Box>
              </Button>
            )}

            {application &&
              application.seller &&
              !application.seller.name.includes("Quecan") &&
              !(application.stage < 200 || !application.data.zohoReqId) && (
                <>
                  <DropdownMenuButton
                    variant="outlined"
                    title="Download Signed PDF"
                  >
                    <MenuItem
                      disabled={allSignedPDFLoading}
                      onClick={() => {
                        downloadAllSignedPDFs()
                      }}
                    >
                      {allSignedPDFLoading ? (
                        <CircularProgress
                          color="secondary"
                          size={30}
                          sx={{ p: 1 }}
                        />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Entire Application Package
                    </MenuItem>
                    <MenuItem
                      disabled={isFetchingPdf}
                      onClick={() => {
                        downloadSingleSignedPDF()
                      }}
                    >
                      {isFetchingPdf && !allSignedPDFLoading ? (
                        <CircularProgress
                          color="secondary"
                          size={30}
                          sx={{ p: 1 }}
                        />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Signed PDF
                    </MenuItem>
                  </DropdownMenuButton>
                </>
              )}

            {application &&
              application.seller &&
              !application.seller.name.includes("Quecan") &&
              (application.stage < 200 || !application.data.zohoReqId) && (
                <>
                  <DropdownMenuButton
                    variant="outlined"
                    title="Download as PDF"
                  >
                    <MenuItem
                      disabled={allUnsignedPDFLoading}
                      onClick={() => {
                        downloadAllUnsignedPDFs()
                      }}
                    >
                      {allUnsignedPDFLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Entire Application Package
                    </MenuItem>
                    <MenuItem
                      disabled={singleUnsignedPDFLoading}
                      onClick={() => {
                        downloadSingleUnsignedPDF()
                      }}
                    >
                      {singleUnsignedPDFLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <PictureAsPdf />
                      )}{" "}
                      Download Unsigned Credit Agreement
                    </MenuItem>
                  </DropdownMenuButton>
                </>
              )}
          </Stack>
        </Box>
      </Stack>
      {bankReferenceChartData &&
        Object.keys(bankReferenceChartData.balances).length > 0 && (
          <InvisibleBankReferenceChart
            bankReferenceChart={bankReferenceChartData}
          />
        )}
      {showRequestInfoDialog && (
        <RequestInformationDialog
          application={application}
          open
          onClose={() => {
            setShowRequestInfoDialog(false)
            refetchTimeline()
          }}
        />
      )}
      {showCustomDocuments && (
        <RequestCustomDocumentDialog
          application={application}
          open
          onClose={() => {
            setShowCustomDocuments(false)
            refetchTimeline()
            refetchRequests()
          }}
        />
      )}
      {showApprovalDialog && (
        <ApprovalDialog
          application={application}
          open
          onClose={() => {
            refetch()
            refetchTimeline()
            setRefreshCommentTrigger(refreshCommentTrigger.current + 1)
            setShowApprovalDialog(false)
          }}
        />
      )}
      {showRequestApprovalDialog && (
        <RequestApprovalDialog
          application={application}
          open
          onClose={() => {
            setRefreshCommentTrigger(refreshCommentTrigger.current + 1)
            setShowRequestApprovalDialog(false)
          }}
          refetch={refetch}
        />
      )}
      {users && showAssignmentDialog === "CREDIT_MANAGER" && (
        <AssignmentSelectionDialog
          open={!!showAssignmentDialog}
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id as string, user.id, () => {
              refetch()
            })
          }}
          contacts={users
            .filter((user) => user?.groups?.includes(Group.CreditManager))
            ?.sort((a, b) => {
              if (!a.firstName) {
                return 1
              }
              if (!b.firstName) {
                return -1
              }
              return a.firstName < b.firstName ? -1 : 1
            })}
        />
      )}
      {users && showAssignmentDialog === "SALES" && (
        <AssignmentSelectionDialog
          open={!!showAssignmentDialog}
          onClose={() => {
            setShowAssignmentDialog(undefined)
          }}
          onSelect={(user) => {
            setShowAssignmentDialog(undefined)
            assignApplication(application.id as string, user.id, () => {
              refetch()
            })
          }}
          contacts={users
            .filter((user) => user?.groups?.includes(Group.Sales))
            ?.sort((a, b) => {
              if (!a.firstName) {
                return 1
              }
              if (!b.firstName) {
                return -1
              }
              return a.firstName < b.firstName ? -1 : 1
            })}
        />
      )}
    </>
  )
}
