import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  useMediaQuery,
} from "@mui/material"
import ReactPhoneInput from "react-phone-input-material-ui"

import { CreditApplication } from "src/types"
import { useState, useEffect } from "react"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { useLocation, useParams } from "react-router-dom"

import FormLabel from "src/components/label/FormLabel"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"
import { APPLICANT_TYPES, BUSINESS_TYPE_OPTIONS } from "src/statics"
import {
  FORM_INPUT_NAMES,
  PAGE_LABEL_USER_DETAILS,
  USER_CONFIG_APPLICANT_TYPE,
  USER_CONFIG_BUSINESS_TYPE,
} from "./constants"
import { isSubSectionEnabled } from "./template_helpers"
import {
  getAdditionalApplicantTypes,
  getApplicantTypes,
  getBusinessTypes,
  hasCustomAddressQuestions,
} from "../utils"
import { FixedHeightTextField } from "./UserRegistrationSection"

type Props = {
  application?: CreditApplication
  data: CreditApplication["data"]
  errors: CreditApplication["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: (
    onVerificationNeeded: () => void,
    onFormValidationError: () => undefined,
  ) => void
  handleBack?: () => void
  activeStep: number
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  handleBack,
  activeStep,
}: Props) => {
  const [loading, setLoading] = useState(false)

  const [showApplicantType, setShowApplicantType] = useState(true)

  const params = useParams()
  const { id } = params

  const { search } = useLocation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = new URLSearchParams(search)
  const businessId = queryParams.get("business_id") || ""

  const { data: template } = useApplicationTemplate(
    application?.seller?.id,
    false,
    id ? !application?.seller?.id : !businessId,
    id,
  )

  useEffect(() => {
    if (template) {
      if (
        template.formTemplate.pages
          .find((step) => step.label === PAGE_LABEL_USER_DETAILS)
          ?.config?.find(
            (config) => config.label === USER_CONFIG_APPLICANT_TYPE,
          )?.value === false
      ) {
        onChange(FORM_INPUT_NAMES.APPLICANT_TYPE, APPLICANT_TYPES.CORPORATION)
        onChange(FORM_INPUT_NAMES.BUSINESS_TYPE, "Other")
        setShowApplicantType(false)
      }
    }
  }, [template, onChange])

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          User Details
        </Typography>
      )}
      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        Who is the point of contact for this application?
      </Typography>
      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}
      <form>
        <Box
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            width: "100%",
            gap: matches ? "0" : "32px",
            alignItems: "center",
            marginBottom: matches ? "0" : "16px",
          }}
        >
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <FormLabel labelName="First Name" required idFor="first-name" />

            <TextField
              id="first-name"
              fullWidth
              placeholder={matches ? "Enter First Name" : ""}
              label={!matches ? "First Name" : ""}
              type="text"
              value={data.firstName}
              onChange={(event) => {
                onChange("firstName", event.target.value)
              }}
              error={Boolean(errors?.firstName)}
              inputProps={
                hasCustomAddressQuestions(
                  template?.customFields,
                  PAGE_LABEL_USER_DETAILS,
                  data,
                )
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
            />
          </FormControl>
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <FormLabel labelName="Last Name" required idFor="last-name" />

            <TextField
              id="last-name"
              required
              fullWidth
              label={!matches ? "Last Name" : ""}
              placeholder={matches ? "Enter Last Name" : ""}
              type="text"
              value={data.lastName}
              onChange={(event) => {
                onChange("lastName", event.target.value)
              }}
              error={Boolean(errors?.lastName)}
              inputProps={
                hasCustomAddressQuestions(
                  template?.customFields,
                  PAGE_LABEL_USER_DETAILS,
                  data,
                )
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: matches ? "column" : "row",
            width: "100%",
            gap: matches ? "0" : "32px",
            alignItems: "center",
          }}
        >
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <FormLabel labelName="Contact Email" required idFor="email" />

            <TextField
              fullWidth
              placeholder={matches ? "Enter Email" : ""}
              label={!matches ? "Email" : ""}
              type="email"
              id="email"
              value={data.email}
              onChange={(event) => {
                onChange("email", event.target.value)
              }}
              error={Boolean(errors?.email)}
              inputProps={
                hasCustomAddressQuestions(
                  template?.customFields,
                  PAGE_LABEL_USER_DETAILS,
                  data,
                )
                  ? {
                      style: { height: "56px" },
                    }
                  : {}
              }
            />
          </FormControl>
          <Box
            style={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              alignItems: "center",
              gap: matches ? "0" : "32px",
            }}
          >
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <FormLabel labelName="Phone Number" />
              <ReactPhoneInput
                value={data.userPhoneNumber}
                label={matches ? "" : "Phone Number"}
                country={"us"}
                onChange={(event) => {
                  onChange("userPhoneNumber", "+" + event)
                }}
                component={FixedHeightTextField}
                containerStyle={{
                  width: "100%",
                }}
                inputProps={{
                  id: "phone-number",
                  required: true,
                  error: Boolean(errors?.userPhoneNumber),
                  template: template,
                  data: data,
                }}
              />
            </FormControl>
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <FormLabel labelName="Extension" idFor="phone-number-ext" />

              <TextField
                id="phone-number-ext"
                fullWidth
                placeholder={matches ? "Extension" : ""}
                label={!matches ? "Extension" : ""}
                type="text"
                value={data.userPhoneNumberExt}
                onChange={(event) => {
                  onChange("userPhoneNumberExt", event.target.value)
                }}
                inputProps={
                  hasCustomAddressQuestions(
                    template?.customFields,
                    PAGE_LABEL_USER_DETAILS,
                    data,
                  )
                    ? {
                        style: { height: "56px" },
                      }
                    : {}
                }
              />
            </FormControl>
          </Box>
        </Box>
        {showApplicantType && (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: matches ? "0" : "32px",
              alignItems: "center",
            }}
          >
            <FormControl
              fullWidth
              margin="normal"
              style={{ flex: "1 1 0px" }}
              required
            >
              <FormLabel labelName="Applicant Type" />
              {!matches && (
                <InputLabel id="applicant-type-select">
                  Applicant Type
                </InputLabel>
              )}
              <Select
                id="applicant-type-select"
                label={matches ? "" : "Applicant Type"}
                value={data.applicantType}
                onChange={(event) => {
                  onChange("applicantType", event.target.value)
                }}
                style={{ height: "56px" }}
                error={Boolean(errors?.applicantType)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    if (matches) {
                      return (
                        <Typography sx={{ color: "text.disabled" }}>
                          Select
                        </Typography>
                      )
                    }
                  }

                  return selected
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {getApplicantTypes(template).map((type) => (
                  <MenuItem value={type} key={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {getAdditionalApplicantTypes(template)
              .concat([
                APPLICANT_TYPES.LLC,
                APPLICANT_TYPES.CORPORATION,
                APPLICANT_TYPES.PARTNERSHIP,
                APPLICANT_TYPES.LIMITED_PARTNERSHIP,
                APPLICANT_TYPES.SOLE_PROPRIETORSHIP,
                APPLICANT_TYPES.NON_PROFIT,
              ])
              .includes(data.applicantType) &&
              isSubSectionEnabled(
                template,
                PAGE_LABEL_USER_DETAILS,
                USER_CONFIG_BUSINESS_TYPE,
              ) && (
                <>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{ flex: "1 1 0px" }}
                    required
                  >
                    <FormLabel labelName="Business Type" />
                    {!matches && (
                      <InputLabel id="business-type-select">
                        Business Type
                      </InputLabel>
                    )}{" "}
                    <Select
                      id="business-type-select"
                      label={matches ? "" : "Business Type"}
                      value={data.businessType}
                      onChange={(event) => {
                        onChange("businessType", event.target.value)
                      }}
                      style={{ height: "56px" }}
                      error={Boolean(errors?.businessType)}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected === undefined || selected === "") {
                          if (matches) {
                            return (
                              <Typography sx={{ color: "text.disabled" }}>
                                Select
                              </Typography>
                            )
                          }
                        }

                        return selected
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {template &&
                        getBusinessTypes(template).map((type) => (
                          <MenuItem value={type} key={"business" + type}>
                            {type}
                          </MenuItem>
                        ))}

                      <MenuItem value={"Other"} key={"businessOther"}>
                        Other
                      </MenuItem>
                    </Select>
                  </FormControl>

                  {data.businessType === "Other" && (
                    <FormControl
                      fullWidth
                      margin="normal"
                      style={{ flex: "1 1 0px" }}
                      required
                    >
                      <TextField
                        id="specify-business-type"
                        margin="normal"
                        label="Specify Business Type"
                        type="text"
                        required
                        value={data.businessTypeOther}
                        onChange={(event) => {
                          onChange(
                            FORM_INPUT_NAMES.BUSINESS_TYPE_OTHER,
                            event.target.value,
                          )
                        }}
                        inputProps={
                          hasCustomAddressQuestions(
                            template?.customFields,
                            PAGE_LABEL_USER_DETAILS,
                            data,
                          )
                            ? {
                                style: { height: "56px" },
                              }
                            : {}
                        }
                      />
                    </FormControl>
                  )}
                </>
              )}
          </Box>
        )}

        {getAdditionalApplicantTypes(template)
          .concat([
            APPLICANT_TYPES.LLC,
            APPLICANT_TYPES.CORPORATION,
            APPLICANT_TYPES.PARTNERSHIP,
            APPLICANT_TYPES.LIMITED_PARTNERSHIP,
            APPLICANT_TYPES.NON_PROFIT,
          ])
          .includes(data.applicantType) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: matches ? "0" : "32px",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth margin="normal">
              <FormLabel labelName="Role in the business" />
              {!matches && <InputLabel id="role-select">Role</InputLabel>}

              <Select
                labelId="role-label"
                id="role-select"
                label={matches ? "" : "Role"}
                value={data.role}
                onChange={(event) => {
                  onChange("role", event.target.value)
                }}
                style={{ height: "56px" }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    if (matches) {
                      return (
                        <Typography sx={{ color: "text.disabled" }}>
                          Select
                        </Typography>
                      )
                    }
                  }

                  return selected
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {["Owner", "Sales", "Finance", "Procurement", "Other"].map(
                  (role) => (
                    <MenuItem value={role} key={role}>
                      {role}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
            {data.role === "Other" && (
              <FormControl fullWidth margin="normal">
                <TextField
                  id="role-other"
                  margin="normal"
                  label="Specify Role"
                  type="text"
                  required
                  value={data.roleOther}
                  onChange={(event) => {
                    onChange(FORM_INPUT_NAMES.ROLE_OTHER, event.target.value)
                  }}
                  inputProps={
                    hasCustomAddressQuestions(
                      template?.customFields,
                      PAGE_LABEL_USER_DETAILS,
                      data,
                    )
                      ? {
                          style: { height: "56px" },
                        }
                      : {}
                  }
                />
              </FormControl>
            )}
          </Box>
        )}
        {template?.customFields && (
          <ReusableCustomQuestions
            application={application}
            associatedPage="User Details"
            data={data["customFields"]}
            dataKeyResponses={data}
            errors={errors}
            onChange={onCustomChange}
            overrideHeight={
              hasCustomAddressQuestions(
                template?.customFields,
                PAGE_LABEL_USER_DETAILS,
                data,
              )
                ? true
                : false
            }
          />
        )}

        <MobileNavButtonsBlock
          click={() => {
            setLoading(true)
            onContinue(
              () => {
                setLoading(false)
                console.log("no-op")
              },
              () => {
                setLoading(false)
                return undefined
              },
            )
          }}
          disable={false}
          loading={loading}
          setLoading={setLoading}
          handleBack={handleBack}
          activeStep={activeStep}
        />
      </form>
    </>
  )
}
