import * as React from "react"

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import { StepIconProps } from "@mui/material/StepIcon"
import Check from "@mui/icons-material/Check"

import { styled } from "@mui/material/styles"
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material"

export type Step = {
  label: string
  date: string
  completed: boolean
  metadata?: any
}

const TimelineConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const TimelineIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
    }),
    "& .TimelineStepIcon-completedIcon": {
      color:
        theme.palette.mode === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[500],
      zIndex: 1,
      fontSize: 18,
    },
    "& .TimelineStepIcon-circle": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }),
)

function TimelineStepIcon(props: StepIconProps) {
  const { active, completed, className } = props
  return (
    <TimelineIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="TimelineStepIcon-completedIcon" />
      ) : (
        <div className="TimelineStepIcon-circle" />
      )}
    </TimelineIconRoot>
  )
}

type Props = {
  steps: Step[]
  activeStep: number
}

export default function TimelineStepper({ steps, activeStep }: Props) {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<TimelineConnector />}
        style={{ width: "100%" }}
      >
        {steps ? (
          steps.map((step, index) => {
            return (
              <Step key={step.label + index}>
                <StepLabel StepIconComponent={TimelineStepIcon}>
                  <Typography variant="subtitle2">{step.label}</Typography>
                  <Typography variant="caption">{step.date}</Typography>
                </StepLabel>
              </Step>
            )
          })
        ) : (
          <></>
        )}
      </Stepper>
    </Box>
  )
}
