// @mui
import { IconButton, Stack, Typography } from "@mui/material"
// routes
// components
import Iconify from "src/components/iconify/"
import { CreditApplication } from "src/types"
import { getStatusString } from "src/sections/@dashboard/utils"
import Label from "src/components/label"
import { fDateTime } from "src/utils/formatTime"
import { useNavigate } from "react-router-dom"

export default function CreditApplicationSalesDetailsToolbar({
  application,
}: {
  application: CreditApplication
}) {

  const navigate = useNavigate()

  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          <IconButton
            onClick={() => {
              navigate(-1)
            }}
          >
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Stack spacing={0.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h4">
                {application.data.legalBusinessName ??
                  `${application.data.firstName ?? ""} ${
                    application.data.lastName ?? ""
                  }`}
              </Typography>
              <Label
                variant="soft"
                color={
                  (application.stage === 800 && "success") ||
                  (application.stage < 800 && "warning") ||
                  (application.stage === 900 && "error") ||
                  "default"
                }
              >
                {getStatusString(application.stage)}
              </Label>
              {application.data.customerId && (
                <Label variant="soft" color="default">
                  Customer ID {application.data.customerId}
                </Label>
              )}
            </Stack>
            {application.approvedAt && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Latest Approval
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.approvedAt as string)}
                </Label>
              </Stack>
            )}
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body2" sx={{ color: "text.disabled" }}>
                Submitted
              </Typography>
              <Label variant="soft" color="default">
                {fDateTime(application.createdAt)}
              </Label>
            </Stack>
            {application.lastViewed && (
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography variant="body2" sx={{ color: "text.disabled" }}>
                  Last Accessed
                </Typography>
                <Label variant="soft" color="default">
                  {fDateTime(application.lastViewed)}
                </Label>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
