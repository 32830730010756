import {
  Button,
  Card,
  CardHeader,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material"
import Label from "src/components/label"
import TableHeadCustom from "src/components/table/table-head-custom"
import { useInternalUser } from "src/queries/internal/useInternalUser"
import { Business, Group, User, UserToBusinessRequest } from "src/types"
import CancelIcon from "@mui/icons-material/Cancel"
import { confirm } from "src/components/confirm/"
import { useState } from "react"
import AddGroupToUserDialog from "./components/AddGroupToUserDialog"
import { usePatchRemoveUserFromGroup } from "src/queries/internal/usePatchRemoveUserFromGroup"
import { enqueueSnackbar } from "notistack"
import { useDeleteInternalUserToBusiness } from "src/queries/internal/useDeleteInternalUserToBusiness"
import { useInternalSendInviteEmail } from "src/queries/internal/useInternalSendInviteEmail"
import AddUserToBusinessDialog from "./components/AddUserToBusinessDialog"
import AddUserSelectableInviteDialog from "./components/AddUserSelectableInviteDialog"
import EmailPreferencesDialog from "./components/EmailPreferencesDialog"
import ViewInvitedUsersDialog from "./components/ViewInvitedUsersDialog"

export default function ClientUserDetails({
  id,
  business,
}: {
  id: string
  business: Business
}) {
  const { data, refetch } = useInternalUser(id as string)
  const TABLE_HEAD = [
    { id: "username", label: "Username", align: "left" },
    { id: "name", label: "Full Name", align: "left" },
    { id: "email", label: "Email", align: "left" },
    { id: "group", label: "Group", align: "left" },
    { id: "action", label: "Action", align: "left" },
  ]
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const [groupQuery, setGroupQuery] = useState<number | null>(null)
  const [openAddGroupToUserDialog, setOpenAddGroupToUserDialog] =
    useState(false)
  const [openUserToBusinessDialog, setOpenUserToBusinessDialog] =
    useState(false)
  const [openInviteUserViaEmailDialog, setInviteUserViaEmailDialog] =
    useState(false)
  const [openEmailPreferencesDialog, setOpenEmailPreferencesDialog] =
    useState<User | null>(null)
  const [currentUser, setCurrentUser] = useState<User | null>(null)

  const [openSelectableUserInviteDialog, setSelectableUserInviteDialog] =
    useState(false)

  const handleGroupToUserClose = () => {
    setOpenAddGroupToUserDialog(false)
    setCurrentUser(null)
  }
  const { execute: executeRemoveUserFromGroup } = usePatchRemoveUserFromGroup()

  const {
    execute: executeSendInviteEmail,
    isLoading: isLoadingSendInviteEmail,
  } = useInternalSendInviteEmail(() => {
    enqueueSnackbar("Email Sent", {
      variant: "success",
    })
  })

  const { execute: executeDeleteInternalUserToBusiness } =
    useDeleteInternalUserToBusiness(() => {
      refetch()
      enqueueSnackbar("User removed from business", {
        variant: "success",
      })
    })

  const filterQuery = (data: User[]) => {
    let userList = data
    if (groupQuery) {
      userList = userList.filter((user) => {
        return user.groups?.includes(groupQuery)
      })
    }

    if (searchQuery) {
      return userList.filter((user) => {
        return (
          (user.username &&
            user.username.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (user.email &&
            user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (user.firstName &&
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (user.lastName &&
            user.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      })
    }
    return userList
  }
  return (
    <>
      <Container sx={{ p: 2 }}>
        <Stack spacing={3}>
          <Paper variant="outlined">
            <Stack
              spacing={3}
              direction="row"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Card sx={{ width: 1 }}>
                <CardHeader title="Users" />
                <Stack sx={{ marginX: 2 }}>
                  <TextField
                    label="Search"
                    margin="normal"
                    fullWidth
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Stack>
                <Stack direction="row" spacing={1} sx={{ marginX: 3 }}>
                  {[
                    Group.NetNowAdmin,
                    Group.NeedsSelfOnboarding,
                    Group.CreditManager,
                    Group.Buyer,
                    Group.Sales,
                    null,
                  ].map((group, index) => {
                    return (
                      <Stack
                        key={index}
                        sx={{ mb: 2 }}
                        onClick={() => {
                          setGroupQuery(group)
                        }}
                      >
                        <Label variant="soft" color={"default"}>
                          {group && Group[group]}
                          {null === group && "All"}
                        </Label>
                      </Stack>
                    )
                  })}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setInviteUserViaEmailDialog(true)
                    }}
                  >
                    Invite User via Email
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setOpenUserToBusinessDialog(true)
                    }}
                  >
                    Add User Existing To Business
                  </Button>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      setSelectableUserInviteDialog(true)
                    }}
                  >
                    View Invited Users
                  </Button>
                </Stack>
                <TableContainer sx={{ mt: 3, overflow: "unset" }}>
                  <Table sx={{ minWidth: 800 }}>
                    <TableHeadCustom headLabel={TABLE_HEAD} />
                    <TableBody>
                      {data &&
                        filterQuery(data).map((row) => (
                          <TableRow key={row.id}>
                            <TableCell align="left">{row.username}</TableCell>
                            <TableCell align="left">
                              {row.firstName} {row.lastName}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="center">
                              {row.groups &&
                                row.groups.map((value, index) => {
                                  return (
                                    <>
                                      <Label key={index} sx={{ m: 0.3 }}>
                                        {Group[value]}{" "}
                                        <CancelIcon
                                          sx={{ color: "red" }}
                                          onClick={() => {
                                            confirm(
                                              "Are you sure you want to remove " +
                                                row.firstName +
                                                " " +
                                                row.lastName +
                                                " (" +
                                                row.email +
                                                ")" +
                                                " from the " +
                                                Group[value] +
                                                " group?",
                                            )
                                              .then(
                                                () => {
                                                  executeRemoveUserFromGroup(
                                                    {
                                                      userId: row.id,
                                                      groupId: value,
                                                    },
                                                    () => {
                                                      refetch()
                                                      enqueueSnackbar(
                                                        "User updated",
                                                        {
                                                          variant: "success",
                                                        },
                                                      )
                                                    },
                                                  )
                                                  return
                                                },
                                                () => {
                                                  console.log("Info")
                                                },
                                              )
                                              .catch(() => {
                                                console.log("Error")
                                              })
                                          }}
                                        />
                                      </Label>
                                    </>
                                  )
                                })}
                            </TableCell>
                            <TableCell align="center">
                              <Stack direction="column" spacing={1}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setCurrentUser(row)
                                    setOpenAddGroupToUserDialog(true)
                                  }}
                                >
                                  Add Group
                                </Button>{" "}
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    confirm(
                                      "Are you Sure You want to remove " +
                                        row.firstName +
                                        " " +
                                        row.lastName +
                                        " (" +
                                        row.email +
                                        ") from " +
                                        business.name +
                                        "?",
                                    )
                                      .then(
                                        () => {
                                          executeDeleteInternalUserToBusiness({
                                            user: row.id,
                                            business: business.id as string,
                                          })
                                          return
                                        },
                                        () => {
                                          console.log("Info")
                                        },
                                      )
                                      .catch(() => {
                                        console.log("Error")
                                      })
                                  }}
                                >
                                  Remove User
                                </Button>
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setOpenEmailPreferencesDialog(row)
                                  }}
                                >
                                  Email Preference
                                </Button>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  disabled={isLoadingSendInviteEmail}
                                  onClick={() => {
                                    executeSendInviteEmail(
                                      {
                                        user: row.id,
                                        business: business.id as string,
                                      } as UserToBusinessRequest,
                                      () => {
                                        return
                                      },
                                    )
                                  }}
                                >
                                  Send Invite Email
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
              {currentUser && (
                <AddGroupToUserDialog
                  user={currentUser}
                  open={openAddGroupToUserDialog}
                  onClose={handleGroupToUserClose}
                  refetch={refetch}
                />
              )}
              {openUserToBusinessDialog && (
                <AddUserToBusinessDialog
                  open={openUserToBusinessDialog}
                  onClose={() => setOpenUserToBusinessDialog(false)}
                  business={business}
                  refetch={refetch}
                />
              )}
              {openInviteUserViaEmailDialog && (
                <AddUserSelectableInviteDialog
                  open={openInviteUserViaEmailDialog}
                  onClose={() => setInviteUserViaEmailDialog(false)}
                  business={business as Business}
                  refetch={refetch}
                />
              )}
              {openEmailPreferencesDialog && (
                <EmailPreferencesDialog
                  open={Boolean(openEmailPreferencesDialog)}
                  onClose={() => setOpenEmailPreferencesDialog(null)}
                  user={openEmailPreferencesDialog}
                  business={business}
                />
              )}
              {openSelectableUserInviteDialog && (
                <ViewInvitedUsersDialog
                  open={openSelectableUserInviteDialog}
                  onClose={() => setSelectableUserInviteDialog(false)}
                  business={business}
                  refetch={refetch}
                />
              )}
            </Stack>
          </Paper>
        </Stack>
      </Container>
    </>
  )
}
