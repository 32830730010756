import { QueryClient } from "react-query"
import { useTypedMutation } from "../useTypedMutation"

export function useSendManualBankReferenceRequest(
  application_id: string,
  onSuccess?: () => void,
) {
  const ENDPOINT = `/application/bank/${application_id}/request`
  const post = useTypedMutation<object>(
    "post/application/bank/request",
    onSuccess,
  )

  const execute = (onSuccess?: () => void) => {
    post.mutate(
      {
        endpoint: ENDPOINT,
        body: {},
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
      },
    )
  }
  return { ...post, execute }
}
