import { LoadingButton } from "@mui/lab"
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Grid,
  Typography,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material"
import { Stack } from "@mui/system"
import { useFormik } from "formik"
import { usePatchApplication } from "src/queries/credit/usePatchApplication"
import { CreditApplication } from "src/types"
import { confirm } from "src/components/confirm"
import { NumericFormat } from "react-number-format"
import { convertCommaSeparatedToNumber } from "src/utils/formatNumber"
import { useCallback, useState } from "react"
import { useApplicationTemplate } from "src/queries/credit/useApplicationTemplate"
import { NET_TERMS } from "src/statics"
import { BUSINESS_PREFERENCES, usePreference } from "src/hooks/use-preference"

export default ({
  application,
  onClose,
  open,
}: {
  application: CreditApplication
  onClose: () => void
  open: boolean
}) => {
  const { execute, isLoading } = usePatchApplication(() => {
    onClose()
  })

  const { preference } = usePreference(
    BUSINESS_PREFERENCES.SEND_APPROVAL_EMAIL_DEFAULT,
    application.seller,
  )

  const [sendApproval, setSendApproval] = useState(
    preference !== undefined ? Number(preference) : 1,
  )

  const { data: template } = useApplicationTemplate(
    application.seller?.id || "",
    false,
    true,
  )

  const terms = template?.approvalTerms || NET_TERMS

  const approve = useCallback(
    (valuesCopy: Partial<CreditApplication>) => {
      execute(
        application.id || "",
        valuesCopy,
        () => null,
        () => null,
        { notifyBuyer: sendApproval.toString() },
      )
    },
    [execute, application.id, sendApproval],
  )

  const formik = useFormik({
    initialValues: {
      data: {
        customerId: application.data.customerId,
        creditLimit: application.data.creditLimit,
        netTerms: terms.map((t) => t.value).includes(application.data.netTerms)
          ? application.data.netTerms
          : "other",
        otherTerms: !terms
          .map((t) => t.value)
          .includes(application.data.netTerms)
          ? application.data.netTerms
          : undefined,
        currency:
          application.data.currency || application.seller?.currency || "USD",
      },
      stage: 800,
    },
    enableReinitialize: true,
    onSubmit: (values: Partial<CreditApplication>) => {
      const valuesCopy = { ...values }
      if (valuesCopy.data) {
        valuesCopy.data.creditLimit = convertCommaSeparatedToNumber(
          valuesCopy.data.creditLimit,
        )
      }

      if (
        valuesCopy.data &&
        valuesCopy.data.netTerms === "other" &&
        valuesCopy.data.otherTerms
      ) {
        valuesCopy.data.netTerms = valuesCopy.data.otherTerms
      }

      approve(valuesCopy)
    },
  })
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Approve Application</DialogTitle>
      <Divider style={{}} />
      <DialogContent>
        <Stack
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Grid container>
            <Grid item md={8}>
              <Typography>Approved Credit Limit</Typography>
            </Grid>
            <Grid item md={4}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <NumericFormat
                  customInput={TextField}
                  fullWidth
                  value={formik.values.data?.creditLimit || ""}
                  onChange={(e) => {
                    formik.setFieldValue("data.creditLimit", e.target.value)
                  }}
                  allowLeadingZeros
                  thousandSeparator=","
                />
                <Select
                  labelId="currency-select"
                  id="currency-select"
                  value={formik.values.data?.currency}
                  onChange={(e) => {
                    formik.setFieldValue("data.currency", e.target.value)
                  }}
                >
                  <MenuItem value={"USD"}>USD</MenuItem>
                  <MenuItem value={"CAD"}>CAD</MenuItem>
                </Select>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={8}>
              <Typography>Net Terms</Typography>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <InputLabel id="net-terms">Net Terms</InputLabel>
                <Select
                  labelId="net-terms"
                  id="net-terms-select"
                  value={formik.values.data?.netTerms}
                  label="Net Terms"
                  onChange={(e) => {
                    formik.setFieldValue("data.netTerms", e.target.value)
                    if (e.target.value !== "other") {
                      formik.setFieldValue("data.otherTerms", undefined)
                    }
                  }}
                >
                  <MenuItem value={undefined} disabled>
                    Select
                  </MenuItem>
                  {terms.map((term) => {
                    return (
                      <MenuItem key={term.value} value={term.value}>
                        {term.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {formik.values.data?.netTerms === "other" && (
            <Grid container>
              <Grid item md={8}>
                <Typography>Please specify the terms</Typography>
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  value={formik.values.data?.otherTerms || ""}
                  onChange={(e) => {
                    formik.setFieldValue("data.otherTerms", e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item md={8}>
              <Typography>Customer ID</Typography>
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                value={formik.values.data?.customerId || ""}
                onChange={(e) => {
                  formik.setFieldValue("data.customerId", e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <FormControl>
              <FormLabel
                id="approval-email-label"
                style={{ margin: "8px 0 8px 0" }}
              >
                Choose one of the options below:
              </FormLabel>
              <RadioGroup
                aria-labelledby="approval-email-label"
                defaultValue={1}
                name="radio-buttons-group"
                value={sendApproval}
                onChange={(e) => setSendApproval(Number(e.target.value))}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Send an email to the buyer with the approval details"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Do not send an approval email to the buyer"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {sendApproval === 1 && (
            <Grid container>
              <Grid item md={4}>
                Notes to share with buyer
              </Grid>
              <Grid item md={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Notes"
                  value={formik.values.data?.approvalNotes || ""}
                  onChange={(e) => {
                    formik.setFieldValue("data.approvalNotes", e.target.value)
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Stack>
      </DialogContent>
      <Divider style={{}} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={() => {
            if (
              !formik.values.data?.creditLimit ||
              !formik.values.data?.netTerms
            ) {
              confirm(
                "You are about to approve this application without setting a credit limit or net terms. We recommend entering the terms and credit limit. This data will be included in the approval email your buyer receives.",
                { okLabel: "Approve" },
              )
                .then((result) => {
                  if (result) {
                    formik.submitForm()
                  }
                  return
                })
                .catch(() => {
                  // Do nothing
                })
            } else {
              formik.submitForm()
            }
          }}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
